<template>
  <section>
    <b-row class="pricing-card">
      <b-col class="mx-auto">
        <b-row>
          <b-col md="6">
            <b-card>
              <h1>เพิ่มวิธีการชำระเงิน</h1>
              <b-card no-body class="card-payment">
             
                <b-card-body>
               
                  <b-form>
                    <b-row>
                      <b-col cols="12">
                        <b-form-group
                          label="Card Number"
                          label-for="payment-card-number"
                          class="mb-2"
                        >
                          <cleave
                            id="payment-card-number"
                            v-model="cardNumber"
                            class="form-control"
                            :raw="false"
                            :options="options.creditCard"
                            placeholder="2133 3244 4567 8921"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col sm="6">
                        <b-form-group
                          label="Expiry"
                          label-for="expiry"
                          class="mb-2"
                        >
                          <b-form-input
                            id="expiry"
                            type="number"
                            placeholder="MM/YY"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col sm="6">
                        <b-form-group
                          label="CVV / CVC"
                          label-for="cvv-cvc"
                          class="mb-2"
                        >
                          <b-form-input
                            id="cvv-cvc "
                            type="number"
                            placeholder="123"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12">
                        <b-form-group
                          label="Input Name"
                          label-for="name"
                          class="mb-2"
                        >
                          <b-form-input id="name" placeholder="Curtis Stone" />
                        </b-form-group>
                      </b-col>

                      <b-form-checkbox
                        id="checkbox-9"
                        name="checkbox-9"
                        v-model="Remember_me"
                      >
                        บัตรเดบิต/เครดิตของคุณจะถูกใช้สำหรับการชำระเงินค่าแพ็กเกจและฟีเจอร์เสริมรายเดือนของ
                        socio
                        บัตรของคุณจะถูกทดลองเรียกเก็บเงินในขั้นตอนนี้เพื่อให้แน่ใจว่าบัตรสามารถใช้งานได้
                        หลังจากนั้นระบบจะทำการคืนเงินให้โดยอัตโนมัติ
                        การเพิ่มบัตรเดบิต/เครดิตหมายถึงคุณได้ตกลงตาม
                      </b-form-checkbox>
                      <br />
                      <b-col cols="12">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          block
                          variant="primary"
                        >
                          ชำระเงิน
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-form>
                  <!--/ form -->
                </b-card-body>
              </b-card>
            </b-card>
          </b-col>
          <b-col md="6">
       
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BFormCheckbox,
  BRow,
  BCol,
  BCard,
  BImg,
  BCardText,
  BListGroup,
  BListGroupItem,
  BButton,
  BBadge,
  BModal,
  VBModal,
  BFormRadioGroup,
  BFormGroup,
  BFormInput,
  BForm,
  BFormText,
  BFormDatalist,
  BFormSelect,
  BCardBody,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BPopover,
  BAlert,
  BLink,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import { required, email } from "@validations";
import Logo from "@core/layouts/components/Logo.vue";
import Cleave from "vue-cleave-component";
/* eslint-disable global-require */
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BBadge,
    BImg,
    BModal,
    BFormRadioGroup,
    BFormGroup,
    BFormInput,
    BForm,
    BFormText,
    BFormDatalist,
    BFormSelect,
    BCardBody,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BPopover,
    BAlert,
    BLink,
    Logo,
    Cleave,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      cardNumber: "",
      Remember_me: true,
      options: {
        creditCard: {
          creditCard: true,
        },
      },
    };
  },
  created() {},
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          alert("form submitted!");
        }
      });
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-pricing.scss";
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>